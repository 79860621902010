import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import Swiper from "react-id-swiper"
import "swiper/dist/css/swiper.min.css"
// import ProductSwiper from "@components/productSwiper"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import marked from "marked"
import slugify from "slugify"

//icons
// import WishlistIcon from "@images/wishlist.svg"

import ProductOptions from "../components/shop/ProductOptions"
import AddToBag from "../components/ecwid_widgets/AddToBag"
import QuoteModal from "../components/modal/QuoteModal"

const ProductTemplateSample = ({ data }) => {
  const product = data.ecwidProducts

  const categories = data?.categories?.nodes
  const allProducts = data?.allProducts?.nodes

  const relatedProductsIds = product.relatedProducts.productIds
  let relatedProducts = relatedProductsIds?.map((id) => {
    const item = allProducts.find((el) => el.ecwid_id === id)
    return formatItem(item) || null
  })

  function chooseRandom(arr, num = 1) {
    if (!arr || arr.length === 0) {
      // Return an empty array if the input array is empty or undefined
      return []
    }

    const res = []
    for (let i = 0; i < num; ) {
      const random = Math.floor(Math.random() * arr.length)

      // Ensure the element exists and is not already in the result array
      if (arr[random] && res.indexOf(arr[random]) === -1) {
        res.push(arr[random])
        i++
      }
    }
    return res
  }

  function createSlug(text) {
    return slugify(text, {
      replacement: "-", // replace spaces with replacement
      remove: /[*+~.()'"!:@?]/g, // regex to remove characters
      lower: true, // result in lower case
    })
  }

  function formatItem(item) {
    return {
      name: item.name,
      image: item.originalImageUrl,
      minPrice: item.defaultDisplayedPriceFormatted,
      link: `/${createSlug(item.name)}`,
    }
  }

  const { productCount, categoryId, enabled } =
    product?.relatedProducts?.relatedCategory
  if (enabled) {
    const filterProducts = allProducts?.filter((el) =>
      el.categoryIds.includes(categoryId)
    )
    chooseRandom(filterProducts, productCount).forEach((element) => {
      relatedProducts.push(formatItem(element))
    })
  }

  let productCategories = product.categories.map((category) => {
    const info = categories?.find((cat) => cat.ecwid_id === category.id)
    return { ...category, info }
  })

  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    speed: 800,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    slidesPerView: 1,
    navigation: {
      nextEl: "#product-gallery-next",
      prevEl: "#product-gallery-prev",
    },
  }

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 5,
    touchRatio: 0.2,
    slideToClickedSlide: true,
  }

  const [productOptions, setProductOptions] = useState([])
  const [productCombination, setProductCombination] = useState(null)
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    if (product?.options?.length) {
      let _options = []
      product.options.forEach((option) => {
        if (option.type === "CHECKBOX") {
          _options.push({
            name: option.name,
            value: [option.choices[option?.defaultChoice || 0].text],
          })
        } else {
          _options.push({
            name: option.name,
            value: option.choices[option?.defaultChoice || 0].text,
          })
        }
      })
      setProductOptions(_options)
    }
  }, [])

  useEffect(() => {
    if (productOptions) {
      if (product?.combinations?.length) {
        setProductCombination(null)

        for (let index = 0; index < product.combinations.length; index++) {
          const combi = product.combinations[index]
          let thisIsIt = false

          for (let index2 = 0; index2 < combi.options.length; index2++) {
            const element = combi.options[index2]

            const x = productOptions.find(
              (opt) => opt.name === element.name && opt.value === element.value
            )

            if (!x) {
              thisIsIt = false
              break
            }

            thisIsIt = true
          }

          if (thisIsIt) setProductCombination(combi)
        }
      }
    }
  }, [productOptions])

  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = gallerySwiper
    }
  }, [gallerySwiper, thumbnailSwiper])

  return (
    <Layout headerType="solid">
      <SeoComponent
        title={product.seoTitle}
        description={product.seoDescription}
      />
      <div className="solid-header-page-spacer" />
      <div className="container">
        <ol className="breadcrumb justify-content-center justify-content-md-start">
          <li className="breadcrumb-item">
            <Link to="/">
              <span>Home</span>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/" aria-current="page" className="btn-link disabled">
              <span>{product.name}</span>
            </Link>
          </li>
        </ol>
        <div className="pt-4">
          <h1 className="font-weight-bold text-center text-md-left">
            {product.name}
          </h1>
        </div>
        <div className="pt-5">
          <div className="row">
            <div className="col-md-6 pr-md-4 mb-4 mb-md-0">
              <LazyLoadComponent>
                <div className="product-gallery-swiper mb-3">
                  <Swiper {...gallerySwiperParams}>
                    {product.media.images.map((photo) => (
                      <div
                        className="product-gallery-slide"
                        style={{
                          backgroundImage: `url(${photo.imageOriginalUrl} )`,
                        }}
                        key={photo.id}
                      ></div>
                    ))}
                  </Swiper>
                  <div className="product-gallery-nav">
                    <div
                      id="product-gallery-next"
                      className="swiper-button-next"
                      tabIndex="0"
                      role="button"
                      aria-label="Next slide"
                    ></div>

                    <div
                      id="product-gallery-prev"
                      className="swiper-button-prev"
                      tabIndex="0"
                      role="button"
                      aria-label="Previous slide"
                    ></div>
                  </div>
                </div>
              </LazyLoadComponent>
              <LazyLoadComponent>
                <div className="product-thumbs-swiper-wrapper">
                  <Swiper {...thumbnailSwiperParams}>
                    {product.media.images.map((photo) => (
                      <div
                        className="thumbnail-slide"
                        style={{
                          backgroundImage: `url(${photo.imageOriginalUrl})`,
                        }}
                        key={photo.id}
                      ></div>
                    ))}
                  </Swiper>
                </div>
              </LazyLoadComponent>
            </div>
            <div className="col-md-6 pl-md-4">
              <div className="product-form-wrapper">
                <div className="w-100 p-3 d-flex align-items-center justify-content-center bg-light">
                  <span className="configured-price font-weight-bolder">
                    {productCombination?.defaultDisplayedPriceFormatted.replace(
                      /\s+/g,
                      ","
                    ) ||
                      product.defaultDisplayedPriceFormatted.replace(
                        /\s+/g,
                        ","
                      )}
                  </span>
                  <span className="text-muted d-inline-block pl-1">
                    {" "}
                    as configured
                  </span>
                </div>

                {product.options.map((option, index) => (
                  <div className="form-group" key={index}>
                    <div className="form-row">
                      <div className="col-4 col-lg-3">
                        <label className="col-form-label" htmlFor="quantity">
                          {option.name}
                        </label>
                      </div>
                      <div className="col-8 col-lg-9">
                        <ProductOptions
                          {...option}
                          setProductOptions={setProductOptions}
                          productOptions={productOptions}
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="form-group">
                  <div className="form-row">
                    <div className="col-4 col-lg-3">
                      <label className="col-form-label" htmlFor="quantity">
                        Quantity
                      </label>
                    </div>
                    <div className="col-4 col-lg-3 col-md-3">
                      <input
                        type="number"
                        className="form-control"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="border-top mt-4">
                  <AddToBag
                    classNames="btn btn-block mt-3"
                    product={product}
                    quantity={quantity}
                    options={productOptions}
                    ecwidId={product.ecwid_id}
                    inStock={product.quantity}
                    unlimited={product.unlimited}
                  />
                  {/* <button
                className="btn btn-primary d-flex justify-content-center align-items-center w-100 mt-4"
                type="submit"
              >
                <img src={CartIcon} width="20px" className="mr-1" alt="" />
                Add to Cart
              </button> */}
                </div>
                {/* <div className="mt-3">
              <button className="btn btn-primary-transparent d-flex justify-content-center align-items-center w-100">
                <img
                  src={WishlistIcon}
                  width="20px"
                  className="mr-1"
                  alt=""
                />
                Add to Wishlist
              </button>
            </div> */}
                <p className="text-center mt-3">
                  Contact us to discuss a custom wood project or explore our
                  pre-configured items available online in our shop.{" "}
                  <QuoteModal values={{ product, quantity, productOptions }} />{" "}
                  <i className="fa fa-angle-right"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="py-10">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2 className="page-content-h2">About This Product</h2>
              {product?.description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(product.description),
                  }}
                />
              )}
              <div className="pt-4 text-center">
                <Link to="/" className="btn btn-primary">
                  Ask a Question
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* {relatedProducts.length > 0 && (
      <div className="py-10 border-top">
        <h2 className="text-center font-italic font-weight-light mb-5 lined-heading">
          Recommended for You
        </h2>
        <LazyLoadComponent>
          <ProductSwiper
            products={relatedProducts || []}
            bgSize="contain"
          />
        </LazyLoadComponent>
      </div>
    )} */}
      </div>
    </Layout>
  )
}

// const ProductTemplateSample = ({ location }) => (
//   <Layout headerType="solid" location={location}>
//     <SeoComponent title="404: Not Found" />
//     <div className="solid-header-page-spacer" />
//     <div className="pb-10">
//       <div className="container">
//         <div className="row justify-content-center text-center">
//           <div className="col-lg-8">
//             <h1 className="mb-4">Sorry, this page does not exist.</h1>
//             <p className="mb-0">
//               Please check if the URL is entered correctly. If you think this is
//               an error, please contact us.
//             </p>
//             <Link to="/" className="mt-4 btn btn-primary">
//               Return Home
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   </Layout>
// )
export default ProductTemplateSample

export const product = graphql`
  query ProductQuery($id: Int!) {
    ecwidProducts(ecwid_id: { eq: $id }) {
      inStock
      ecwid_id
      sku
      name
      unlimited
      quantity
      defaultDisplayedPriceFormatted
      options {
        defaultChoice
        name
        required
        type
        choices {
          text
        }
      }
      description
      media {
        images {
          id
          image1500pxUrl
          image160pxUrl
          image400pxUrl
          image800pxUrl
          imageOriginalUrl
          isMain
          orderBy
        }
      }
      seoTitle
      seoDescription
      categories {
        enabled
        id
      }
      relatedProducts {
        relatedCategory {
          categoryId
          enabled
          productCount
        }
      }
      combinations {
        combinationNumber
        defaultDisplayedPriceFormatted
        id
        inStock
        price
        sku
        options {
          name
          value
        }
      }
    }
  }
`
