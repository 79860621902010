import React from "react"

const ProductOptions = (props) => {
  switch (props.type) {
    case "RADIO":
      return <RadioType {...props} />
    case "SELECT":
      return <OptionSelect {...props} />
    case "CHECKBOX":
      return <CheckboxType {...props} />
    default:
      return null
  }
}

const OptionSelect = ({ name, choices, setProductOptions, productOptions }) => {
  const selected = productOptions.find((el) => el.name === name)

  return (
    <select
      className="form-control"
      value={selected?.value || ""}
      onChange={(e) => {
        setProductOptions((prev) =>
          prev.map((el) =>
            el.name === name ? { ...el, value: e.target.value } : el
          )
        )
      }}
    >
      {choices?.map((choice, index) => (
        <option value={choice.text} key={index}>
          {choice.text}
        </option>
      ))}
    </select>
  )
}

const RadioType = ({ name, choices, setProductOptions, productOptions }) => {
  return (
    <div>
      {choices.map((choice, index) => {
        const selected = productOptions.find((el) => el.name === name)

        return (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={choice.text}
              value={choice.text}
              checked={selected?.value === choice.text}
              onChange={(e) => {
                const { name, value } = e.target
                setProductOptions((prev) =>
                  prev.map((el) => (el.name === name ? { ...el, value } : el))
                )
              }}
            />
            <label className="form-check-label" htmlFor={choice.text}>
              {choice.text}
            </label>
          </div>
        )
      })}
    </div>
  )
}

const CheckboxType = ({ name, choices, setProductOptions, productOptions }) => {
  return (
    <div>
      {choices.map((choice, index) => {
        const selected = productOptions.find((el) => el.name === name)

        return (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              name={name}
              id={choice.text}
              value={choice.text}
              checked={selected?.value.includes(choice.text)}
              onChange={(e) => {
                const { name, value, checked } = e.target

                setProductOptions((prev) =>
                  prev.map((el) => {
                    if (el.name !== name) return el
                    if (checked) {
                      return { ...el, value: [...el.value, value] }
                    } else {
                      return {
                        ...el,
                        value: el.value.filter((val) => val !== value),
                      }
                    }
                  })
                )
              }}
            />
            <label className="form-check-label" htmlFor={choice.text}>
              {choice.text}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default ProductOptions
